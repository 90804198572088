import Image from "next/image"
/* This example requires Tailwind CSS v2.0+ */
const posts = [
  {
    title: '7 Ways to Improve your Business using your P&L',
    href: '/blog/7-ways-to-improve-your-business-using-your-p-and-l',
    date: 'Mar 16, 2023',
    imageUrl:'https://storage.googleapis.com/pinto-blog/ways_to_improve_your_business_using_your_pl_by_pinto_financial_3700ed0465/ways_to_improve_your_business_using_your_pl_by_pinto_financial_3700ed0465.png',
  },
  {
    title: 'Your Business Needs a (Financial) Plan',
    href: '/blog/your-business-needs-a-financial-plan',
    date: 'Feb 10, 2023',
    imageUrl: 'https://storage.googleapis.com/pinto-blog/Blog_Post_5_637ee2274e/Blog_Post_5_637ee2274e.png',
  },
  {
    title: '9 Ways Small Businesses Make Poor Financial Decisions',
    href: '/blog/9-ways-small-businesses-make-poor-financial-decisions',
    date: 'Feb 12, 2023',
    imageUrl: 'https://storage.googleapis.com/pinto-blog/Pinto_Blog2_BRANDED_cf8ddcb305/Pinto_Blog2_BRANDED_cf8ddcb305.png',
  },
]

export default function Blog() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Learn about building profitably</h2>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article
              key={post.href}
              className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
            >
              <Image 
                src={post.imageUrl}
                alt="Blog Post"
                width={600}
                height={600}
                className="absolute inset-0 -z-10 h-full w-full object-cover"
                loading="lazy"
              />
              <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
              <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

              <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                <time dateTime={post.datetime} className="mr-8">
                  {post.date}
                </time>
              </div>
              <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                <a href={post.href}>
                  <span className="absolute inset-0" />
                  {post.title}
                </a>
              </h3>
            </article>
          ))}
        </div>
      </div>
    </div>
  )
}
