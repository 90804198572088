import Dayporter from '/public/dayporter-headshot.png'
import Feedmill from '/public/feedmillcoffee-headshot.png'
import Grownetics from '/public/grownetics-headshot.png'
import Christian from '/public/christian-headshot.png'
import GoEdison from '/public/goedison-headshot.png'

export const TestimonialsList = [
  {
    image: Dayporter,
    text: 'As a serial entrepreneur it\'s critical to have a team that keeps your monthly financials straight. Pinto\'s monthly bookkeeping and budget have been critical as we\'ve scaled and grown DayPorter. I\'ve recommended Pinto to multiple clients of mine, and I\'ll continue to do so. Thank you Pinto!',
    name: 'James Harper',
    title: 'Founder of DayPorter',
  },
  {
    image: Feedmill, 
    text: 'Pinto has been exactly what my business needed from an accounting perspective. They are responsive, easy to work with, and very detail oriented. Working with Pinto is as easy as it gets and that\'s what I need to keep growing Feed Mill Coffee.',
    name: 'Nick Graf',
    title: 'Owner of Feed Mill Coffee Co.',
  },
  {
    image: Grownetics, 
    text: 'It\'s been a pleasure working with Pinto, they have a smooth onboarding process and their monthly reports make it easy to see where my business stands at a glance. Pinto was able to clean up our books and implement project based accounting which has been great to understand our true costs of delivery. Any questions are handled quickly and professionally, I highly recommend Pinto!',
    name: 'Vince Harkiewicz',
    title: 'Founder of Grownetics Inc',
  },
  {
    image: Christian, 
    text: 'Awesome monthly recap video, it\'s super helpful! I love the monthly reports and how quick videos can save meetings!',
    name: 'Christian Baum',
    title: 'Owner of A. Christian Baum, LLC',
  },
  {
    image: GoEdison,
    text: 'Pinto\'s service is more cost effective than a bookkeeper on staff (but you literally get one), you can manage your business cleanly with their monthly reports, and you don\'t have to worry about it at all. I don\'t promote things often, but when I do, it\'s because I\'m a believer. I 100% promote Pinto to business owners.',
    name: 'Joel Allen',
    title: 'Owner of GoEdison',
  },
]