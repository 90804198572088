import Image from "next/image"
import { LuBookMarked, LuBookCopy, LuBookOpenCheck, LuBarChart4, LuBean, LuGavel, LuCloudSun } from "react-icons/lu";

import { 
  BuildingLibraryIcon,
  MapIcon, 
  ArrowTrendingUpIcon
} from "@heroicons/react/24/outline"

const features = [
  {
    name: 'Bookkeeping.',
    description: 'Accurate and timely financial records, prepared by experts',
    icon: LuBookOpenCheck,
  },
  {
    name: 'Reporting.',
    description: 'Financial reports that provide clear insights into your business performance. <a class="text-sm font-semibold leading-6 text-pinto hover:underline" href="/demo">See an interactive report  <span aria-hidden="true">&rarr;</span></a>',
    icon: LuBarChart4,
  },
  {
    name: 'Forecasting.',
    description: 'Proactive 12-month planning to ensure a profitable future.', 
    icon: LuCloudSun,
  },
  {
    name: 'Tax.',
    description: 'Stress-free tax filing for small businesses',
    icon: LuGavel,
  },
]

export default function FeaturesLeft() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pt-4 lg:pl-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-pinto">Our core services</h2>
              <p className="mt-2 text-3xl font-medium tracking-tight text-gray-900 sm:text-4xl">Systems to <span className='text-pinto'>Build Profitably</span></p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                We&apos;re building a simple system to consistently generate profits from your business. We combine software and our expert team to customize the experience for you.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute top-1 left-1 h-5 w-5 text-pinto" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline" dangerouslySetInnerHTML={{ __html: feature.description }}></dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="flex items-start justify-end lg:order-first relative">
            <Image
              src="/budget-screenshot.png"
              alt="Budget Page Screenshot"
              className="w-[48rem] max-w-none shadow-2xl ring-1 ring-gray-900/10 rounded-xl sm:w-[57rem]"
              width={2432}
              height={1442}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  )
}