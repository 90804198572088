import Image from 'next/image'
import Link from 'next/link'
import LogoCultivate from '/public/logo-cultivate.png'
import LogoGivLocal from '/public/logo-givlocal.png'
import LogoAutomate from '/public/logo-automatearmy.png'
import LogoGoEdison from '/public/logo-goedison.png'
import LogoGrownetics from '/public/logo-grownetics.png'
import LogoRateProvisions from '/public/logo-raceprovisions.png'

/* This example requires Tailwind CSS v2.0+ */
export default function Logos() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <p className="text-center text-lg font-semibold text-gray-900">
          Trusted by small businesses worldwide
        </p>
        <div className="mt-6 grid grid-cols-2 gap-4 md:grid-cols-3 lg:mt-8">
          <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <Image className="max-h-12 object-contain" src={LogoCultivate} alt="Cultivate All" loading="lazy"/>
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <Image className="max-h-12 object-contain" src={LogoGivLocal} alt="Givlocal" loading="lazy"/>
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <Image className="max-h-12 object-contain" src={LogoAutomate} alt="Automate Army"  loading="lazy" />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <Link href="https://www.goedison.com/">
              <Image className="max-h-12 object-contain" src={LogoGoEdison} alt="Go Edison" loading="lazy" />
            </Link>
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <Image className="max-h-12 object-contain" src={LogoGrownetics} alt="Grownetics" loading="lazy" />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <Image className="max-h-12 object-contain" src={LogoRateProvisions} alt="Race Provisions" loading="lazy" />
          </div>
        </div>
      </div>
    </div>
  )
}

